import styled from 'styled-components'

const Btn = styled.button`
    position: ${props => props.top ? 'absolute' : 'relative'};
    box-shadow: 2px 2px 3px rgba(126, 126, 126, 0.25), -3px -3px 7px rgba(255, 255, 255, 0.8);
    background-color: ${props => props.backgroundColor || "#F1F1F1"};
    border-radius: 25px;
    width: ${props => props.width ? props.width : '100px'};
    height: ${props => props.height ? props.height : '35px'};
    outline:none;
    border: none;
    cursor: pointer; 
    margin: 10px .5rem;
    text-align: center;
    justify-content: ${props => props.text ? 'space-around' : 'center'};
    display: flex;
    top : ${props => props.top || "auto"};
    right: ${props => props.right};
    align-items: center;
    text-decoration: none;
    margin: auto;

    &:hover {
        box-shadow: inset -3px -3px 7px #FFFFFF, inset 5px 4px 11px rgba(0, 0, 0, 0.25);
    }
`

const Text = styled.a`
    color: ${props => props.color || "black"};
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.085em;
    text-decoration: none;
`

const Button = ({ link, top, left, name, Bkcolor, color, width, height }) => {
  
    return (
        <Btn onClick={link} top={top} left={left} backgroundColor={Bkcolor} width={width} height={height}>
            <Text color={color}>{name}</Text>
        </Btn>
    );
};

export default Button;