import logo from './ReMi_logo.png';
import demo from './ReMi_demo.mp4';
import demoThumbnail from './ReMi_demo_thumbnail.jpg';
import './App.css';
import { useState } from 'react';
import Field from './Components/Field';
import Button from './Components/Button';

function Newsletter() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState({ success: null, message: '' });
  const [loading, setLoading] = useState(false);

  const url = `https://europe-west1-remi-prod.cloudfunctions.net/newsletter/api/subscribe`;

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const inputHandler = (e) => {
    setEmail(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setMessage({ success: null, message: '' });

    if (!validateEmail(email)) {
      setMessage({ success: false, message: 'Please enter a valid email address.' });
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        setEmail('');
        setMessage({ success: true, message: data.message });
      } else {
        setMessage({ success: false, message: data.message });
      }
    } catch (error) {
      setLoading(false);
      setMessage({ success: true, message: 'An error occurred. Please try again later.' });
    }
  };

  return (
    <div className="App glassmorphic-container">
      <div className="leftCol">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="video-container-mobile">
          <video controls className="custom-video" poster={demoThumbnail}>
            <source src={demo} type="video/mp4" />
              {/* Add additional source elements for different video formats if needed */}
              Your browser does not support the video tag.
          </video>
        </div>
        <form className="form" onSubmit={submitHandler}>
          <p className="callToAction" style={message ? { marginBottom: '0' } : null}><b><big>Subscribe to our Newsletter</big></b><br></br><small>Get a monthly update about our products straight to your inbox.</small></p>
          {message && <p className="message" style={{ color: message.success === true ? 'green' : 'red' }}>{message.message}</p>}
          <Field type="email" onChange={inputHandler} name="email" value={email}>email</Field>
          <Button
            name="Subscribe"
            id="subButton"
            type="submit"
            disabled={loading}
          >
            {loading ? 'Subscribing...' : 'Subscribe'}
          </Button>
        </form>
      </div>
      <div className="video-container-desktop">
        <video controls className="custom-video" poster={demoThumbnail}>
          <source src={demo} type="video/mp4" />
            {/* Add additional source elements for different video formats if needed */}
            Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
}

export default Newsletter;
