import React from 'react';
import logo from './ReMi_logo_dark.png';

const UnsubscribedPage = () => {
  return (
    <div className="glassmorphic-container">
      <a className="top-left-element" href="https://remi-prod.web.app">
        <img src={logo} className="Logo-dark" alt="logo" />
      </a>
      <div className="centered-elements">
        <h1>Unsubscribed Successfully</h1>
        <p style={{ margin: '0 20px', maxWidth: '600px' }}>
          We've removed your email address from our mailing list. If you change your mind in the future, you're always welcome to resubscribe.

          If you have any further questions or concerns, please don't hesitate to contact us.
        </p>
      </div>
    </div>
  );
};

export default UnsubscribedPage;
