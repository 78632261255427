import React from 'react';
import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  width: 400px;
  max-width: 70vw;
  height: 150px;
  padding: 10px;
  border: none;
  margin-top: 5px;
  resize: none;
  padding: 15px 15px 0px 15px;
  background: #E6E6E6;
  box-shadow: inset -3px -3px 7px #FFFFFF, inset 5px 4px 11px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  margin-bottom: 10px;
  &::placeholder{
      text-transform: uppercase;
      color: black;
  }
  &:focus{
      outline: none;
      border-color: inherit;
  }
`;

const CustomTextarea = ({ value, onChange, placeholder }) => {
  const handleChange = (e) => {
    onChange(e.target.value); // Update the value directly in the parent component
  };

  return (
    <StyledTextarea
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      required
    />
  );
};

export default CustomTextarea;
