import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UnsubscribedPage from './UnsubscribedPage'; 
import Home from './Home';
import NotFoundPage from './NotFoundPage';
import EmailIcon from './Components/EmailIcon';
import { useState } from 'react';
import EmailModal from './Components/EmailModal';

const App = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/unsubscribed" element={<UnsubscribedPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <EmailIcon onClick={openModal} />
      <EmailModal isOpen={modalOpen} onClose={closeModal} />
    </Router>
  );
};

export default App;
