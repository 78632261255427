import React from 'react';
import logo from './ReMi_logo_dark.png';

const NotFoundPage = () => {
  return (
    <div className="glassmorphic-container">
        <a className="top-left-element" href="https://remi-prod.web.app">
            <img src={logo} className="Logo-dark" alt="logo" />
        </a>
        <div className="centered-elements">
            <h1>404 - Not Found</h1>
            <p>The page you're looking for does not exist.</p>
        </div>
    </div>
  );
};

export default NotFoundPage;
