import React, { useState } from 'react';
import styled from 'styled-components';
import Field from './Field';
import CustomTextarea from './CustomTextarea';
import Button from './Button';

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const ModalContent = styled.div`
    position: relative; /* Ensure relative positioning for absolute children */
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 40px 15px;
    box-shadow: 2px 2px 3px rgba(126, 126, 126, 0.25), -3px -3px 7px rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 520px;
    max-width: 80vw;
    margin: 0 auto;
    cursor: default;

    @media (max-height: 725px) {
        max-height: 90vh;
        position: absolute;
        top: 20px;
        bottom: 20px;
        overflow: auto;
    }
`;

const CloseButton = styled.span`
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 30px;
`;

const EmailModal = ({ isOpen, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({ success: null, message: '' });
    const [emailData, setEmailData] = useState({
        email: '',
        name: '',
        body: ''
    });

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChange = (fieldName, value) => {
        setEmailData((prevData) => ({
            ...prevData,
            [fieldName]: value
        }));
    };
      
      

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage({ success: null, message: '' }); 

        if (!emailData.name || !emailData.email || !emailData.body) {
            setMessage({ success: false, message: 'Please fill in all fields.' });
            return;
          }

        if (!validateEmail(emailData.email)) {
            setMessage({ success: false, message: 'Email is invalid. Please enter a valid email address.' });
            return;
        }
        // Sending email logic...
        try {
            setLoading(true)
            const response = await fetch('https://europe-west1-remi-prod.cloudfunctions.net/newsletter/api/contactForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: emailData.email,
                    name: emailData.name,
                    message: emailData.body
                })
            });
            setLoading(false)
        
            if (response.ok) {
                setMessage({ success: true, message: 'Email sent successfully' });
                // Reset form fields after sending the email
                setEmailData({
                    name: '',
                    email: '',
                    body: ''
                });
                // onClose();
            } else {
                setMessage({ success: false, message: 'An error occured, please try again.' });
            }
        } catch (error) {
            setMessage({ success: false, message: 'An error occured, please try again.' });
        }
        
    };

    const handleModalClose = (e) => {
        if (e.target === e.currentTarget) {
        // Close the modal only if the click occurred on the modal background
        onClose();
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <ModalBackground onClick={handleModalClose}>
            <ModalContent>
                <CloseButton onClick={onClose}>&times;</CloseButton>
                <h2 style={{ marginTop: 0, marginBottom: '20px' }}>Contact us</h2>
                <p style={{ margin: '0 0 30px 0', width: '400px', maxWidth: '80vw' }}>Got a question? We’d love to hear from you! Send us a message, and we’ll respond as soon as possible.</p>
                <form onSubmit={handleSubmit}>
                    {message && <p className="message" style={{ color: message.success === true ? 'green' : 'red' }}>{message.message}</p>}
                    <Field 
                        style={{ width: '400px', marginBottom: '15px', borderRadius: '15px' }} 
                        type="text" 
                        onChange={(e) => handleChange("name", e.target.value)}
                        name="name" 
                        value={emailData.name}
                    >Name</Field>
                    <Field
                        style={{ width: '400px', marginBottom: '15px', borderRadius: '15px' }} t
                        ype="email"   
                        onChange={(e) => handleChange("email", e.target.value)}
                        name="email" 
                        value={emailData.email}
                    >Email</Field>
                    <CustomTextarea
                        value={emailData.body}
                        onChange={(value) => handleChange("body", value)}
                        placeholder="Message"
                    />
                    <Button
                        name="Send"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? 'Sending...' : 'Send'}
                    </Button>
                </form>
            </ModalContent>
        </ModalBackground>
    );
};

export default EmailModal;
