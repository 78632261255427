import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    width: 300px;
    max-width: 70vw;
    height: 45px;
    border: none;
    padding: 0px 15px 0px 15px;
    background: #E6E6E6;
    box-shadow: inset -3px -3px 7px #FFFFFF, inset 5px 4px 11px rgba(0, 0, 0, 0.25);
    border-radius: 46px;
    margin-bottom: 10px;
    &::placeholder{
        text-transform: uppercase;
        color: black;
    }
    &:focus{
        outline: none;
        border-color: inherit;
    }
`

function Field({ name, onChange, children, type, style, value }) {
    return (
      <Input
        type={type}
        placeholder={children}
        onChange={onChange}
        id={name}
        name={name}
        style={style}
        value={value} // Use the provided value prop
        required
      />
    );
}

export default Field;